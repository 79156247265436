<template>
  <div v-if="p" class="w-100 fixed position-relative">
    <component
      :is="nftFix ? 'OldPlayer360' : 'Player360'"
      @tapped="writingDelay()"
      v-if="showVideo360 || showVideo"
      :url="video.stream_hls"
      :id="video.video_id"
      :cover="this.autoplay ? video.cover : false"
      :token="loginInfo.token"
      @exit="exit()"
      :options="[]"
      :showOptions="true"
      @handleVideo="() => {}"
      :type="video.video_type"
      :leftFix="true"
      :plain="showVideo"
      :reloadOnLock="true"
    />
    <transition
        appear
        enterActiveClass="animate__animated animate__fadeIn"
        leaveActiveClass="animate__animated animate__fadeOut"
    >
      <Cartiglio v-if="cartiglio && playerActive" />
    </transition>
  </div>
  <Redeem v-else-if="displayRedeem" />
  <div v-else class="AlexBraga" :style="{backgroundImage: backgroundStyle}">
    <div v-if="false" class="AlexBraga__logos">
      <div class="AlexBraga__logos__logo">
        <img src="https://media.tok.tv/alive/assets/static/img/asianFake.png" alt="Asian Fake">
      </div>
      <div class="AlexBraga__logos__logo">
        <img src="https://media.tok.tv/alive/assets/static/img/aliveMusicTvWhite.png" class="alive" alt="Alivemusic.tv">
      </div>
    </div>
    <div v-if="true" class="AlexBraga__head">
      <div class="AlexBraga__head__mainTitle">
        NFT: Spleen Machine Live
        <div class="AlexBraga__head__mainTitle__infos">
          <div class="AlexBraga__head__mainTitle__infos__what">
          </div>
        </div>
      </div>
    </div>
    <div class="AlexBraga__play">
      <div
        class="AlexBraga__play__trailer"
        :class="{ single: !nftStreamUrl}"
        :style="{backgroundImage: 'url(https://media.tok.tv/videoplatform/alive/bd7fcece-d182-4294-8d0f-06198d55e2d7_576.jpg)'}"
        @click="loadVideo('8f6c13a7-56d4-11ed-b286-06fe767c3f71')"
      >
        <PlayBtn :message="get(text, 'gioevan_button', '')" />
      </div>
      <div
          v-if="nftStreamUrl"
          class="AlexBraga__play__nft"
          :style="{backgroundImage: `url(${nftCoverUrl})`}"
      >
        <div class="AlexBraga__play__nft__button" @click="loadUrl(nftStreamUrl)">
          <PlayBtn message="Il tuo NFT" />
        </div>
        <div class="AlexBraga__play__nft__eos" v-if="!nftOwner" :class="{ special: true, [$store.state.browser.name]: true }">
          <p>Diventa proprietario del tuo NFT</p>
          <ol v-if="false">
            <li @click.prevent="$router.push('/alexbraga/eos/crea')">crea un account EOS personale</li>
            <li @click.prevent="$router.push('/alexbraga/eos/proprietario')">invia il nome del tuo account EOS appena creato ad A-LIVE </li>
          </ol>
          <button
              v-if="true"
              @click.prevent="$router.push('/alexbraga/account/alexbraga-ref')"
          >
            Crea il tuo account EOS
          </button>
        </div>
      </div>
      <div v-else class="AlexBraga__play__advice">
        <p>IL TUO NFT SARà DISPONIBILE<br/>IL 5 NOVEMBRE<br/>TORNA QUI PER RISCATTARLO</p>
      </div>
      <a
          v-if="false"
          class="AlexBraga__play__nfts"
          :style="{backgroundImage: 'url(https://media.tok.tv/alive/assets/static/img/nfts.jpg)'}"
          href="/nfts/index.html"
      >
        <PlayBtn message="Tutti gli NFTs" />
      </a>
    </div>
    <div v-if="false" class="AlexBraga__countdown">
      <vue-countdown-timer
          :start-time="'2018-10-10 00:00:00'"
          :end-time="'2022-04-20 20:00:00'"
          :interval="1000"
          label-position="begin"
          :day-txt="'giorni'"
          :hour-txt="'ore'"
          :minutes-txt="'minuti'"
          :seconds-txt="'secondi'">
        <template slot="countdown" slot-scope="scope">
          <div class="AlexBraga__countdown__element">
            <span>{{scope.props.days}}</span>
            <i>{{scope.props.dayTxt}}</i>
          </div>
          <div class="AlexBraga__countdown__element">
            <span>{{scope.props.hours}}</span>
            <i>{{scope.props.hourTxt}}</i>
          </div>
          <div class="AlexBraga__countdown__element">
            <span>{{scope.props.minutes}}</span>
            <i>{{scope.props.minutesTxt}}</i>
          </div>
          <div class="AlexBraga__countdown__element">
            <span>{{scope.props.seconds}}</span>
            <i>{{scope.props.secondsTxt}}</i>
          </div>
        </template>
      </vue-countdown-timer>
    </div>
  </div>
</template>

<script>

import { mapActions, mapMutations, mapState } from 'vuex';
import { get } from 'lodash';
import axios from 'axios';
import Player360 from '@/components/Player360.vue';
import OldPlayer360 from '@/components/OldPlayer360.vue';
import Redeem from '@/components/Redeem.vue';
import Cartiglio from './Cartiglio';
import PlayBtn from './glyphs/PlayBtn';

export default {
  name: 'GioEvan',
  components: {
    PlayBtn,
    Cartiglio,
    Player360,
    OldPlayer360,
    Redeem,
  },
  data() {
    return {
      text: false,
      needFix: false,
      nftInfo: false,
      playerActive: false,
      cartiglio: false,
      p: false,
      get,
      video: false,
      showVideo360: false,
      showVideo: false,
      rbv: false,
      interval: false,
      writing: false,
      writingTo: false,
      autoplay: false,
    };
  },
  methods: {
    ...mapActions(['getMe', 'getPlans', 'getNftCode', 'getNftInfo', 'playVideoById']),
    ...mapMutations(['appReady', 'redirectToIP', 'loadRedeem', 'setLoginInfo', 'setNFTInfo']),
    specialUser(id) {
      const su = [
        1530,
        1104,
        1464,
        1308,
        1548,
        1569,
        1921,
        1827,
        1587,
        1623,
        // 2021,
      ];
      return su.includes(id);
    },
    loadUrl(url) {
      this.autoplay = false;
      this.needFix = true;
      this.p = true;
      this.cartiglio = true;
      this.video = {
        cover: this.nftCoverUrl,
        stream_hls: url,
        title: this.nftTitle,
        video_360: true,
        video_type: "vod"
      };
      this.showVideo360 = true;
      this.showVideo = false;
      this.autoplay = false;
    },
    loadVideo(id) {
      this.autoplay = true;
      const that = this;
      const ep = `/video/${id}`;
      this.api.get(ep, {
        headers: {
          'x-tok-token': get(this.loginInfo, 'token'),
          'Accept-Language': this.locale,
        },
      })
        .then((response) => {
          that.p = true;
          that.video = response.data;
          if (that.video.video_360 || that.video.title.includes('360')) {
            that.showVideo360 = true;
            that.showVideo = false;
          } else {
            that.showVideo360 = false;
            that.showVideo = true;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    exit() {
      this.p = false;
      this.cartiglio = false;
      this.needFix = false;
    },
    handleCanSee() {
      const self = this;
      const canSee = get(this, 'me.active_offers', []).includes('bragaromaeuropa');
      if (canSee) {
        this.appReady(true);
        this.getNftCode(36).then((code) => {
          const nft_id = get(code, 'nft_id', '');
          if (nft_id) {
            self.getNftInfo(nft_id)
                .then((data) => {
                  self.nftInfo = data;
                })
                .catch((e) => {
                  console.log(e);
                });
          }
        });

      } else {
        this.loadRedeem({
          offers: ['bragaromaeuropa'],
          route: '/alexbraga-ref',
          closeOnError: false,
          cb: true,
        });
        this.appReady(true);
      }
    },
  },
  computed: {
    ...mapState([
      'me',
      'loginInfo',
      'locale',
      'api',
      'displayRedeem',
      'nftCode',
      'browser',
      'md',
      // 'nftInfo',
    ]),
    backgroundStyle() {
      // return 'url(https://media.tok.tv/videoplatform/alive/bd7fcece-d182-4294-8d0f-06198d55e2d7_3072.jpg)';
      return 'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7)), url(https://media.tok.tv/videoplatform/alive/bd7fcece-d182-4294-8d0f-06198d55e2d7_3072.jpg)';
    },
    nftFix() {
      return false && this.browser.name === 'chrome' && !this.md.mobile() && this.needFix;
    },
    nftCoverUrl() {
      return get(this.nftInfo, 'coverUrl', false);
    },
    nftId() {
      return get(this.nftInfo, 'id', false);
    },
    nftStreamUrl() {
      return get(this.nftInfo, 'videoUrl', false);
    },
    nftOwner() {
      const owner = get(this.nftInfo, 'owner', false);
      return owner;
    },
    nftTitle() {
      return get(this.nftInfo, 'title', false);
    }
  },
  mounted() {
    const referrer = '/alexbraga-ref';
    const self = this;
    let status = this.$cookies.get(`${window.TOK_NS}storageAccessData`);
    if (!status || status == null) {
      status = this.$ls.get('storageAccessData', false);
    }
    if (status && status != null) {
      this.setLoginInfo(status);
      if (!this.me) {
        this.getMe()
          .then(() => {
            if (get(self, 'me.registered')) {
              self.handleCanSee();
            } else {
              this.$ls.set('referrer', referrer, 3 * 60 * 1000);
              this.redirectToIP({
                router: this.$router,
                registered: true,
              });
            }
          })
          .catch((error) => {
            window.app.$log.error(error);
          });
      } else {
        this.handleCanSee();
      }
    } else {
      this.$ls.set('referrer', referrer, 3 * 60 * 1000);
      this.redirectToIP({
        router: this.$router,
        registered: true,
      });
    }
    axios.get(`/text.json?req=${Math.random()}`)
        .then(({ data }) => {
          self.text = data;
          this.interval = setInterval(() => {
            let hidden = true;
            if (this.nftFix) {
              hidden = document.querySelector('.vjs-user-inactive');
            } else {
              hidden = document.querySelector('.bmpui-ui-controlbar.bmpui-hidden');
            }
            this.playerActive = Boolean(!hidden);

          }, 250);
        });
  },
};
</script>

<style lang="scss" scoped>
  .AlexBraga {
    background-color: #000000;
    // height: 100vh;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    display: flex;
    flex-direction: column;
    &__logos {
      display: flex;
      align-items: center;
      justify-content: center;
      &__logo {
        padding: 10px;
        img {
          width:80px;
          height: auto;
          &.alive {
            width:60px;
          }
        }
      }
    }
    &__head {
      color: #FFFFFF;
      margin-top: 30px;
      &__mainTitle {
        margin: 0 auto;
        font-size: 80px;
        width: 100%;
        max-width: 1000px;
        text-align: center;
        &__infos{
          margin-top: 30px;
          display: flex;
          @media screen and (max-width: 600px) {
            flex-direction: column;
          }
          flex-direction: row;
          justify-content: space-between;
          padding: 0 25px;
          &__what {
            width: 100%;
            @media screen and (max-width: 600px) {
              text-align: center;
              margin-bottom: 10px;
            }
            text-shadow: 0 0 5px rgba(0, 0, 0, 1);
            font-size: 25px;
          }
          &__where {
            @media screen and (max-width: 600px) {
              text-align: center;
            }
            text-shadow: 0 0 5px rgba(0, 0, 0, 1);
            font-size: 25px;
            text-align: right;
          }
        }
      }
    }
    &__play{
      flex: auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
      max-width: 1300px;
      width: 100%;
      margin: 0 auto;
      @media screen and (max-width: 600px) {
        padding-top: 50px;
        flex-direction: column;
        flex: unset;
      }
      @media screen and (min-width: 600px) {
        padding-top: 50px;
      }
      flex-direction: row;
      &__trailer {
        position: relative;
        margin: 30px 30px 30px 30px;
        font-size: 18px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        width: 300px;
        height: calc(300px * 0.75);
        padding: 15px 30px;
        background-color: rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        border: 2px solid #FFFFFF;
        color: #FFFFFF;
        cursor: pointer;
        font-weight: bold;
        text-shadow: 0 0 10px rgba(0, 0, 0, 1);
        background-size: cover;
        background-position: center;
        &.single {
          @media screen and (min-width: 600px) {
            margin-right: 300px;
          }
        }
      }
      &__advice {
        text-align: center;
        text-transform: uppercase;
        position: relative;
        margin: 30px 30px 30px 30px;
        font-size: 24px;
        line-height: 28px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        width: 300px;
        height: calc(300px * 0.75);
        padding: 15px 30px;
        background-color: rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        // border: 2px solid #FFFFFF;
        color: #FFFFFF;
        cursor: pointer;
        font-weight: bold;
        text-shadow: 0 0 10px rgba(0, 0, 0, 1);
        background-size: cover;
        background-position: center;
      }
      &__nft {
        position: relative;
        margin: 30px 30px 30px 30px;
        font-size: 18px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        width: 300px;
        height: calc(300px * 0.75);
        padding: 15px 30px;
        background-color: rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        border: 2px solid #FFFFFF;
        color: #FFFFFF;
        cursor: pointer;
        font-weight: bold;
        text-shadow: 0 0 10px rgba(0, 0, 0, 1);
        background-size: cover;
        background-position: center;
        @media screen and (max-width: 600px) {
          margin-bottom: 150px;
        }
        &__button {
          display: contents;
        }
        &__eos {
          position: absolute;
          bottom: -120px;
          font-size: 13px;
          p {
            margin-bottom: 10px;
          }
          ol {
            padding-left: 10px;
            li {
              list-style: decimal;
              margin-bottom: 10px;
            }
          }
          &.special {
            bottom: -100px;
            &.safari {
              bottom: -120px;
            }
          }
        }
      }
      &__nfts {
        position: relative;
        margin: 30px 30px 30px 30px;
        font-size: 18px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        width: 220px;
        height: calc(220px * 0.75);
        padding: 15px 30px;
        background-color: rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        border: 2px solid #FFFFFF;
        color: #FFFFFF;
        cursor: pointer;
        font-weight: bold;
        text-shadow: 0 0 10px rgba(0, 0, 0, 1);
        background-size: cover;
        background-position: center;
      }
    }
    button {
      width: 80%;
      margin: 0 10%;
      margin-top: 10px;
      background-color: #d7c500;
      &:hover {
        background-color: darken(#d7c500, 30%);
        color: white;
      }
      transition: all 0.3s ease;
      color: #000;
      border-width: 0;
      padding: 5px 20px;
      border-radius: 5px;
      cursor: pointer;
      height: 36px;
      border: 1px solid rgba(0, 0, 0, 0.5);
    }
    &__countdown {
      padding-bottom: 50px;
      color: white;
      display: flex;
      justify-content: center;
      &>div{
        display: flex;
        flex-direction: row;

      }
      &__element{
        margin: 0 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          font-size: 24px;
        }
        i {
          font-size: 10px;
        }
      }
    }
  }
</style>
